export const logoNegative = ['608 134', `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="100%" viewBox="0 0 409 142" enable-background="new 0 0 409 142" xml:space="preserve">
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
d="
M266.000000,143.000000 
C177.333359,143.000000 89.166710,143.000000 1.000047,143.000000 
C1.000031,95.666702 1.000031,48.333397 1.000016,1.000073 
C137.333267,1.000048 273.666534,1.000048 409.999878,1.000024 
C409.999908,48.333256 409.999908,95.666512 409.999939,142.999878 
C362.166656,143.000000 314.333344,143.000000 266.000000,143.000000 
M125.934349,28.064133 
C125.934349,28.064133 125.823479,28.078253 125.585571,27.445349 
C123.394997,25.598248 121.204422,23.751148 118.456718,21.576336 
C117.641853,21.371666 116.826981,21.166998 115.256744,20.577417 
C113.648209,20.271982 112.040405,19.705198 110.431015,19.700644 
C89.183563,19.640528 67.935341,19.588358 46.689034,19.754938 
C44.220753,19.774292 41.762836,21.115311 38.637589,21.670809 
C30.825970,25.180231 26.398554,31.317129 26.296122,39.761868 
C26.034246,61.351364 26.075523,82.947426 26.276308,104.538261 
C26.359020,113.432419 33.400978,122.440628 43.939281,124.105095 
C67.284485,124.043152 90.629684,123.981209 114.718140,124.047340 
C118.474663,121.326935 122.231194,118.606537 126.523750,115.758415 
C126.938889,115.146568 127.354027,114.534721 128.394470,113.597092 
C128.709991,112.719391 129.025513,111.841698 129.405731,110.869385 
C129.405731,110.869385 129.431900,110.757790 129.554535,110.600304 
C129.554535,110.600304 129.626205,110.414024 130.137802,109.884766 
C130.440414,107.421768 130.996124,104.959747 131.005707,102.495613 
C131.083771,82.403954 131.073944,62.311874 131.037277,42.220020 
C131.034637,40.769154 130.610291,39.319061 130.512909,37.073345 
C129.838867,35.691261 129.164841,34.309177 128.472244,32.255192 
C127.621635,30.895136 126.771027,29.535082 125.934349,28.064133 
M355.063690,93.062462 
C355.063690,93.062462 355.101837,93.160881 355.784454,92.898445 
C355.950073,84.888176 356.115662,76.877907 356.340240,68.125847 
C354.432068,59.877563 341.686707,56.499172 332.547333,64.720039 
C326.161591,58.379391 319.198212,58.438381 312.114899,63.335930 
C307.184235,58.762547 304.579376,59.281155 302.678345,65.871658 
C302.763336,73.924957 302.848328,81.978256 302.814941,90.713402 
C305.062134,91.561798 307.309296,92.410194 309.556458,93.258583 
C310.071350,91.376656 310.908264,89.515633 311.032074,87.608322 
C311.268402,83.968346 311.084381,80.301903 311.100159,76.646561 
C311.120239,71.993591 314.563629,68.174774 318.696960,68.190979 
C322.745880,68.206841 325.507874,71.307419 325.664459,76.281685 
C325.789856,80.265495 325.632050,84.257561 325.713135,88.243668 
C325.763824,90.734627 325.655396,93.396820 329.466400,93.263542 
C332.873260,93.144386 334.707977,91.708321 334.668640,88.151886 
C334.624573,84.164680 334.607605,80.175468 334.683075,76.189095 
C334.777863,71.183212 337.479218,68.133186 341.612274,68.211884 
C345.530762,68.286491 348.201080,71.216141 348.360107,75.927132 
C348.500153,80.076447 348.020447,84.284409 348.566101,88.365974 
C348.829193,90.333595 350.822144,92.069939 352.484589,94.139961 
C353.376099,93.795815 354.267609,93.451675 355.063690,93.062462 
M293.609650,92.305595 
C294.420135,91.417236 295.913849,90.544472 295.934540,89.638084 
C296.123169,81.375847 296.272949,73.099747 295.969055,64.844810 
C295.820862,60.819622 292.330292,59.605942 287.966919,62.135330 
C286.346710,62.010788 284.485321,62.358788 283.149536,61.677002 
C278.276978,59.190136 274.043213,59.665325 269.374115,62.598053 
C263.716492,66.151680 260.622345,73.292610 262.194244,81.572990 
C263.548523,88.707298 269.263458,94.671898 275.891937,94.581017 
C281.632263,94.502312 287.081665,91.169815 293.609650,92.305595 
M170.483200,46.346207 
C166.489700,46.348644 162.495789,46.316658 158.502838,46.364925 
C155.555115,46.400558 153.014450,47.327866 153.056808,50.779816 
C153.098312,54.163216 155.453552,55.140144 158.528290,55.085625 
C162.930237,55.007565 167.334610,55.065739 172.485596,55.065739 
C171.536682,56.933960 171.119293,57.954735 170.537216,58.870869 
C164.920654,67.710548 159.216888,76.495384 153.661682,85.373260 
C150.157043,90.974106 151.904953,94.180260 158.586151,94.335373 
C165.571075,94.497536 172.562531,94.377151 179.551132,94.382607 
C182.601517,94.384995 185.545197,93.890999 185.536926,90.060989 
C185.528442,86.136726 182.662704,85.257668 179.320450,85.275352 
C174.934341,85.298553 170.548019,85.281044 165.172394,85.281044 
C167.354706,81.767097 168.877594,79.258095 170.455811,76.784378 
C175.105255,69.496803 179.928177,62.314457 184.385162,54.911491 
C187.270813,50.118469 185.310822,46.613640 179.951462,46.373638 
C177.128311,46.247215 174.294983,46.348255 170.483200,46.346207 
M193.540009,66.177216 
C192.297256,69.686378 190.252960,73.128876 189.954590,76.716576 
C188.838943,90.130913 200.765305,97.707825 215.141403,93.174133 
C217.941116,92.291214 220.036484,90.697418 218.824432,87.946114 
C218.186127,86.497200 215.399948,85.433678 213.481644,85.247650 
C210.930634,85.000267 208.239349,86.216072 205.685287,85.977966 
C203.624069,85.785805 201.676834,84.370949 199.679031,83.498520 
C199.859756,82.961983 200.040482,82.425453 200.221207,81.888924 
C205.143723,81.888924 210.068634,81.985046 214.988144,81.863472 
C220.560410,81.725769 222.356628,79.264420 221.537766,73.990425 
C220.395813,66.635498 215.552551,62.471008 209.153549,60.401760 
C203.249023,58.492413 198.101837,61.074490 193.540009,66.177216 
M365.607697,67.224617 
C361.339478,75.307068 361.735016,83.455544 366.672607,89.162598 
C371.412354,94.640915 380.150452,96.267334 388.351501,92.902687 
C389.525787,92.420914 390.783478,90.673531 390.917847,89.397606 
C391.344055,85.350586 389.389618,84.345749 384.207703,85.584526 
C377.540771,87.178307 374.909943,86.369110 372.928406,82.383507 
C378.859650,81.883446 384.979492,81.700195 390.940796,80.632317 
C392.442200,80.363373 394.673462,77.088654 394.449158,75.493347 
C393.483490,68.625229 390.382690,62.697552 383.163208,60.572639 
C376.211090,58.526413 370.392090,60.787533 365.607697,67.224617 
M253.209518,79.856895 
C255.107346,75.649406 257.239441,71.524918 258.775635,67.189293 
C259.311523,65.676941 258.979431,63.154209 257.996216,61.943836 
C255.476562,58.842072 252.101379,59.928864 250.147720,64.380562 
C247.534302,70.335609 245.136398,76.385239 242.257904,83.330566 
C240.785767,81.590775 239.771194,80.823212 239.338760,79.807739 
C237.204132,74.794968 235.336426,69.666977 233.144608,64.680717 
C231.916855,61.887646 230.054123,59.034119 226.426422,60.657928 
C222.812668,62.275497 223.552841,65.493225 224.844055,68.402855 
C227.333557,74.012688 229.735138,79.674896 232.534286,85.130089 
C235.165421,90.257858 237.564926,95.195602 233.909790,100.813560 
C233.615051,101.266586 233.571747,102.152802 233.813690,102.636665 
C234.662537,104.334320 235.555939,107.174591 236.676529,107.294197 
C238.294449,107.466904 240.871094,106.070305 241.722839,104.587471 
C244.068466,100.503853 245.698822,96.012978 247.671112,91.710258 
C249.382065,87.977646 251.173645,84.282005 253.209518,79.856895 
z"/>
<path fill="#323B41" opacity="1.000000" stroke="none" 
d="
M128.490799,32.927094 
C129.164841,34.309177 129.838867,35.691261 130.101425,37.737446 
C129.477783,39.517982 129.082230,40.634151 129.080597,41.750896 
C129.048981,63.334095 129.053833,84.917381 129.092331,106.500572 
C129.094666,107.805367 129.440308,109.109558 129.626205,110.414024 
C129.626205,110.414024 129.554535,110.600304 129.345551,110.542664 
C129.009552,110.503357 128.882523,110.521690 128.755508,110.540016 
C128.950684,110.681351 129.145874,110.822678 129.341034,110.964005 
C129.025513,111.841698 128.709991,112.719391 127.770401,113.787201 
C126.760139,114.613594 126.373932,115.249870 125.987717,115.886139 
C122.231194,118.606537 118.474663,121.326935 114.041618,123.703438 
C112.719246,123.242500 112.073479,123.023758 111.427521,123.023155 
C89.866722,123.003120 68.305862,122.989029 46.745174,123.040077 
C45.504238,123.043015 44.264614,123.601120 43.024380,123.900848 
C33.400978,122.440628 26.359020,113.432419 26.276308,104.538261 
C26.075523,82.947426 26.034246,61.351364 26.296122,39.761868 
C26.398554,31.317129 30.825970,25.180231 39.490005,21.802710 
C64.190407,21.957340 88.038429,21.997377 111.886314,21.958191 
C113.262131,21.955931 114.636871,21.309153 116.012115,20.962330 
C116.826981,21.166998 117.641853,21.371666 118.788055,22.077747 
C121.354088,24.412191 123.588783,26.245222 125.823479,28.078253 
C125.823479,28.078253 125.934349,28.064133 125.940224,28.440725 
C126.794334,30.187244 127.642563,31.557171 128.490799,32.927094 
M90.616287,40.065441 
C89.006592,39.371990 87.336250,37.964558 85.796555,38.095322 
C70.068687,39.431053 58.226463,47.245434 50.171379,60.643944 
C46.026230,67.538826 43.792435,75.125877 44.043720,84.046661 
C51.713005,84.046661 59.099247,84.046661 67.092125,84.046661 
C67.092125,91.978455 67.092125,99.209358 67.092125,106.415047 
C86.519089,106.178978 103.187683,94.841682 109.860497,77.440521 
C111.915703,72.081024 115.856613,66.589737 111.983376,59.838490 
C105.111847,59.838490 98.183151,59.838490 90.688118,59.838490 
C90.688118,53.230480 90.688118,47.103455 90.616287,40.065441 
z"/>
<path fill="#383E45" opacity="1.000000" stroke="none" 
d="
M352.031769,93.911011 
C350.822144,92.069939 348.829193,90.333595 348.566101,88.365974 
C348.020447,84.284409 348.500153,80.076447 348.360107,75.927132 
C348.201080,71.216141 345.530762,68.286491 341.612274,68.211884 
C337.479218,68.133186 334.777863,71.183212 334.683075,76.189095 
C334.607605,80.175468 334.624573,84.164680 334.668640,88.151886 
C334.707977,91.708321 332.873260,93.144386 329.466400,93.263542 
C325.655396,93.396820 325.763824,90.734627 325.713135,88.243668 
C325.632050,84.257561 325.789856,80.265495 325.664459,76.281685 
C325.507874,71.307419 322.745880,68.206841 318.696960,68.190979 
C314.563629,68.174774 311.120239,71.993591 311.100159,76.646561 
C311.084381,80.301903 311.268402,83.968346 311.032074,87.608322 
C310.908264,89.515633 310.071350,91.376656 309.556458,93.258591 
C307.309296,92.410194 305.062134,91.561798 303.194031,90.094391 
C303.720825,83.780693 303.999207,78.085014 303.955719,72.391792 
C303.936768,69.906967 303.258453,67.427185 302.879578,64.945114 
C304.579376,59.281155 307.184235,58.762547 312.114899,63.335930 
C319.198212,58.438381 326.161591,58.379391 332.547333,64.720039 
C341.686707,56.499172 354.432068,59.877563 355.962341,68.760986 
C355.423584,77.317703 355.262726,85.239296 355.101837,93.160881 
C355.101837,93.160881 355.063690,93.062462 354.765900,93.058304 
C353.656006,93.339775 352.843872,93.625389 352.031769,93.911011 
z"/>
<path fill="#0ED8A3" opacity="1.000000" stroke="none" 
d="
M170.974731,46.346397 
C174.294983,46.348255 177.128311,46.247215 179.951462,46.373638 
C185.310822,46.613640 187.270813,50.118469 184.385162,54.911491 
C179.928177,62.314457 175.105255,69.496803 170.455811,76.784378 
C168.877594,79.258095 167.354706,81.767097 165.172394,85.281044 
C170.548019,85.281044 174.934341,85.298553 179.320450,85.275352 
C182.662704,85.257668 185.528442,86.136726 185.536926,90.060989 
C185.545197,93.890999 182.601517,94.384995 179.551132,94.382607 
C172.562531,94.377151 165.571075,94.497536 158.586151,94.335373 
C151.904953,94.180260 150.157043,90.974106 153.661682,85.373260 
C159.216888,76.495384 164.920654,67.710548 170.537216,58.870869 
C171.119293,57.954735 171.536682,56.933960 172.485596,55.065739 
C167.334610,55.065739 162.930237,55.007565 158.528290,55.085625 
C155.453552,55.140144 153.098312,54.163216 153.056808,50.779816 
C153.014450,47.327866 155.555115,46.400558 158.502838,46.364925 
C162.495789,46.316658 166.489700,46.348644 170.974731,46.346397 
z"/>
<path fill="#3B4248" opacity="1.000000" stroke="none" 
d="
M193.763214,65.878937 
C198.101837,61.074490 203.249023,58.492413 209.153549,60.401760 
C215.552551,62.471008 220.395813,66.635498 221.537766,73.990425 
C222.356628,79.264420 220.560410,81.725769 214.988144,81.863472 
C210.068634,81.985046 205.143723,81.888924 200.221207,81.888924 
C200.040482,82.425453 199.859756,82.961983 199.679031,83.498520 
C201.676834,84.370949 203.624069,85.785805 205.685287,85.977966 
C208.239349,86.216072 210.930634,85.000267 213.481644,85.247650 
C215.399948,85.433678 218.186127,86.497200 218.824432,87.946114 
C220.036484,90.697418 217.941116,92.291214 215.141403,93.174133 
C200.765305,97.707825 188.838943,90.130913 189.954590,76.716576 
C190.252960,73.128876 192.297256,69.686378 193.763214,65.878937 
M207.810745,68.496597 
C203.692429,67.687065 200.937317,69.004242 199.753799,73.657944 
C203.942612,73.657944 207.726685,73.657944 212.945801,73.657944 
C210.939041,71.456123 209.726807,70.126045 207.810745,68.496597 
z"/>
<path fill="#3B4148" opacity="1.000000" stroke="none" 
d="
M365.815552,66.921494 
C370.392090,60.787533 376.211090,58.526413 383.163208,60.572639 
C390.382690,62.697552 393.483490,68.625229 394.449158,75.493347 
C394.673462,77.088654 392.442200,80.363373 390.940796,80.632317 
C384.979492,81.700195 378.859650,81.883446 372.928406,82.383507 
C374.909943,86.369110 377.540771,87.178307 384.207703,85.584526 
C389.389618,84.345749 391.344055,85.350586 390.917847,89.397606 
C390.783478,90.673531 389.525787,92.420914 388.351501,92.902687 
C380.150452,96.267334 371.412354,94.640915 366.672607,89.162598 
C361.735016,83.455544 361.339478,75.307068 365.815552,66.921494 
M372.424103,72.026596 
C376.101196,76.440521 380.441650,72.884026 384.878754,73.951881 
C381.866302,67.086403 378.148895,66.547035 372.424103,72.026596 
z"/>
<path fill="#394046" opacity="1.000000" stroke="none" 
d="
M253.069550,80.213455 
C251.173645,84.282005 249.382065,87.977646 247.671112,91.710258 
C245.698822,96.012978 244.068466,100.503853 241.722839,104.587471 
C240.871094,106.070305 238.294449,107.466904 236.676529,107.294197 
C235.555939,107.174591 234.662537,104.334320 233.813690,102.636665 
C233.571747,102.152802 233.615051,101.266586 233.909790,100.813560 
C237.564926,95.195602 235.165421,90.257858 232.534286,85.130089 
C229.735138,79.674896 227.333557,74.012688 224.844055,68.402855 
C223.552841,65.493225 222.812668,62.275497 226.426422,60.657928 
C230.054123,59.034119 231.916855,61.887646 233.144608,64.680717 
C235.336426,69.666977 237.204132,74.794968 239.338760,79.807739 
C239.771194,80.823212 240.785767,81.590775 242.257904,83.330566 
C245.136398,76.385239 247.534302,70.335609 250.147720,64.380562 
C252.101379,59.928864 255.476562,58.842072 257.996216,61.943836 
C258.979431,63.154209 259.311523,65.676941 258.775635,67.189293 
C257.239441,71.524918 255.107346,75.649406 253.069550,80.213455 
z"/>
<path fill="#383E45" opacity="1.000000" stroke="none" 
d="
M293.084259,92.365005 
C287.081665,91.169815 281.632263,94.502312 275.891937,94.581017 
C269.263458,94.671898 263.548523,88.707298 262.194244,81.572990 
C260.622345,73.292610 263.716492,66.151680 269.374115,62.598053 
C274.043213,59.665325 278.276978,59.190136 283.149536,61.677002 
C284.485321,62.358788 286.346710,62.010788 288.713654,62.069618 
C292.628082,61.836155 293.997314,63.517918 294.056305,66.508087 
C294.213043,74.456490 294.391968,82.404922 294.442993,90.354095 
C294.447266,91.021461 293.558258,91.694565 293.084259,92.365005 
M286.949249,77.439529 
C286.571960,71.719376 283.741150,68.258209 279.334015,68.128639 
C274.964508,68.000168 271.146271,72.012741 270.977112,76.910881 
C270.806366,81.855240 273.762634,85.677238 278.142548,86.174644 
C282.890869,86.713875 285.929291,83.980553 286.949249,77.439529 
z"/>
<path fill="#404A52" opacity="1.000000" stroke="none" 
d="
M129.882004,110.149399 
C129.440308,109.109558 129.094666,107.805367 129.092331,106.500572 
C129.053833,84.917381 129.048981,63.334095 129.080597,41.750896 
C129.082230,40.634151 129.477783,39.517982 130.035980,38.135078 
C130.610291,39.319061 131.034637,40.769154 131.037277,42.220020 
C131.073944,62.311874 131.083771,82.403954 131.005707,102.495613 
C130.996124,104.959747 130.440414,107.421768 129.882004,110.149399 
z"/>
<path fill="#2A2D30" opacity="1.000000" stroke="none" 
d="
M115.634430,20.769875 
C114.636871,21.309153 113.262131,21.955931 111.886314,21.958191 
C88.038429,21.997377 64.190407,21.957340 39.821266,21.889099 
C41.762836,21.115311 44.220753,19.774292 46.689034,19.754938 
C67.935341,19.588358 89.183563,19.640528 110.431015,19.700644 
C112.040405,19.705198 113.648209,20.271982 115.634430,20.769875 
z"/>
<path fill="#4A5156" opacity="1.000000" stroke="none" 
d="
M293.346954,92.335297 
C293.558258,91.694565 294.447266,91.021461 294.442993,90.354095 
C294.391968,82.404922 294.213043,74.456490 294.056305,66.508087 
C293.997314,63.517918 292.628082,61.836155 289.033051,61.916107 
C292.330292,59.605942 295.820862,60.819622 295.969055,64.844810 
C296.272949,73.099747 296.123169,81.375847 295.934540,89.638084 
C295.913849,90.544472 294.420135,91.417236 293.346954,92.335297 
z"/>
<path fill="#282828" opacity="1.000000" stroke="none" 
d="
M43.481831,124.002975 
C44.264614,123.601120 45.504238,123.043015 46.745174,123.040077 
C68.305862,122.989029 89.866722,123.003120 111.427521,123.023155 
C112.073479,123.023758 112.719246,123.242500 113.669991,123.639404 
C90.629684,123.981209 67.284485,124.043152 43.481831,124.002975 
z"/>
<path fill="#37393A" opacity="1.000000" stroke="none" 
d="
M355.443146,93.029663 
C355.262726,85.239296 355.423584,77.317703 355.932861,69.131882 
C356.115662,76.877907 355.950073,84.888176 355.443146,93.029663 
z"/>
<path fill="#18182A" opacity="1.000000" stroke="none" 
d="
M302.778961,65.408386 
C303.258453,67.427185 303.936768,69.906967 303.955719,72.391792 
C303.999207,78.085014 303.720825,83.780693 303.253235,89.753464 
C302.848328,81.978256 302.763336,73.924957 302.778961,65.408386 
z"/>
<path fill="#404A52" opacity="1.000000" stroke="none" 
d="
M125.704529,27.761801 
C123.588783,26.245222 121.354088,24.412191 119.066620,22.241604 
C121.204422,23.751148 123.394997,25.598248 125.704529,27.761801 
z"/>
<path fill="#404A52" opacity="1.000000" stroke="none" 
d="
M128.481522,32.591145 
C127.642563,31.557171 126.794334,30.187244 125.933258,28.496174 
C126.771027,29.535082 127.621635,30.895136 128.481522,32.591145 
z"/>
<path fill="#37393A" opacity="1.000000" stroke="none" 
d="
M352.258179,94.025482 
C352.843872,93.625389 353.656006,93.339775 354.813599,93.080841 
C354.267609,93.451675 353.376099,93.795815 352.258179,94.025482 
z"/>
<path fill="#404A52" opacity="1.000000" stroke="none" 
d="
M126.255737,115.822281 
C126.373932,115.249870 126.760139,114.613594 127.457748,113.950096 
C127.354027,114.534721 126.938889,115.146568 126.255737,115.822281 
z"/>
<path fill="#404A52" opacity="1.000000" stroke="none" 
d="
M129.373383,110.916695 
C129.145874,110.822678 128.950684,110.681351 128.755508,110.540016 
C128.882523,110.521690 129.009552,110.503357 129.284241,110.621414 
C129.431900,110.757790 129.405731,110.869385 129.373383,110.916695 
z"/>
<path fill="#00D29C" opacity="1.000000" stroke="none" 
d="
M90.652206,40.520935 
C90.688118,47.103455 90.688118,53.230480 90.688118,59.838490 
C98.183151,59.838490 105.111847,59.838490 111.983376,59.838490 
C115.856613,66.589737 111.915703,72.081024 109.860497,77.440521 
C103.187683,94.841682 86.519089,106.178978 67.092125,106.415047 
C67.092125,99.209358 67.092125,91.978455 67.092125,84.046661 
C59.099247,84.046661 51.713005,84.046661 44.043720,84.046661 
C43.792435,75.125877 46.026230,67.538826 50.171379,60.643944 
C58.226463,47.245434 70.068687,39.431053 85.796555,38.095322 
C87.336250,37.964558 89.006592,39.371990 90.652206,40.520935 
M78.424538,67.866066 
C74.915337,67.877327 73.393723,70.150742 74.121452,73.041740 
C74.527168,74.653481 77.053429,76.976715 78.357460,76.816017 
C80.171295,76.592484 82.988556,74.408607 83.068710,72.938057 
C83.156464,71.328003 80.617294,69.574768 78.424538,67.866066 
z"/>
<path fill="#DDDEDE" opacity="1.000000" stroke="none" 
d="
M208.162659,68.646286 
C209.726807,70.126045 210.939041,71.456123 212.945801,73.657944 
C207.726685,73.657944 203.942612,73.657944 199.753799,73.657944 
C200.937317,69.004242 203.692429,67.687065 208.162659,68.646286 
z"/>
<path fill="#D8D8D9" opacity="1.000000" stroke="none" 
d="
M372.564270,71.674179 
C378.148895,66.547035 381.866302,67.086403 384.878754,73.951881 
C380.441650,72.884026 376.101196,76.440521 372.564270,71.674179 
z"/>
<path fill="#F4F4F4" opacity="1.000000" stroke="none" 
d="
M286.947144,77.847786 
C285.929291,83.980553 282.890869,86.713875 278.142548,86.174644 
C273.762634,85.677238 270.806366,81.855240 270.977112,76.910881 
C271.146271,72.012741 274.964508,68.000168 279.334015,68.128639 
C283.741150,68.258209 286.571960,71.719376 286.947144,77.847786 
z"/>
<path fill="#2E4247" opacity="1.000000" stroke="none" 
d="
M78.823746,67.875000 
C80.617294,69.574768 83.156464,71.328003 83.068710,72.938057 
C82.988556,74.408607 80.171295,76.592484 78.357460,76.816017 
C77.053429,76.976715 74.527168,74.653481 74.121452,73.041740 
C73.393723,70.150742 74.915337,67.877327 78.823746,67.875000 
z"/>
</svg>
`]
